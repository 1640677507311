<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import api from '@/services/api'

// Route
const route = useRoute()

// State
const pages = ref([])
const selectedFilters = ref([])
const extraInfoOptions = ref([])
const hideDelivered = ref(false)
const hideCollected = ref(false)
const showDeliveryOnly = ref(false)
const dateFilterType = ref('collection')
const selectedDate = ref('')
const selectedLocations = ref([])
const collectionLocations = ref([])
const availableDates = ref([])

// Computed
const hasAnyFilters = computed(() => {
  return selectedFilters.value.length > 0 ||
      hideDelivered.value ||
      hideCollected.value ||
      showDeliveryOnly.value ||
      selectedDate.value !== '' ||
      selectedLocations.value.length > 0
})

const filteredPages = computed(() => {
  // Get all labels first
  const allLabels = pages.value.flat()

  // Apply all filters
  const filteredLabels = allLabels.filter(label => {
    // Extra Info filter
    if (selectedFilters.value.length > 0) {
      if (!label.extraInfo || label.extraInfo === ' | ') {
        return false
      }
      if (!selectedFilters.value.includes(label.extraInfo.trim())) {
        return false
      }
    }

    // Status filters
    if (hideDelivered.value && label.delivered) return false
    if (hideCollected.value && label.collected) return false
    if (showDeliveryOnly.value && !label.deliveryDate) return false

    // Date filter
    if (selectedDate.value) {
      const dateToCheck = dateFilterType.value === 'collection'
          ? label.collectionDate
          : label.deliveryDate
      if (dateToCheck !== selectedDate.value) return false
    }

    // Collection Location filter
    if (selectedLocations.value.length > 0) {
      if (!selectedLocations.value.includes(label.collectionLocation)) {
        return false
      }
    }

    return true
  })

  // Create new pages with 12 labels each
  const newPages = []
  for (let i = 0; i < filteredLabels.length; i += 12) {
    newPages.push(filteredLabels.slice(i, i + 12))
  }

  return newPages
})

const totalFilteredLabels = computed(() => {
  return filteredPages.value.reduce((acc, page) => acc + page.length, 0)
})

// Methods
const formatOrdinal = (str) => {
  try {
    let number = str.replace(/\D/g, '')
    number = parseInt(number, 10)

    if (isNaN(number)) {
      return ""
    }

    let suffix
    if (number % 10 === 1 && number % 100 !== 11) {
      suffix = 'st'
    } else if (number % 10 === 2 && number % 100 !== 12) {
      suffix = 'nd'
    } else if (number % 10 === 3 && number % 100 !== 13) {
      suffix = 'rd'
    } else {
      suffix = 'th'
    }

    return number + suffix
  } catch (e) {
    console.error('Error formatting ordinal:', e)
    return ""
  }
}

const stringToColour = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = hash * 31 + str.charCodeAt(i)
    hash |= 0  // Convert to 32-bit integer
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

const getLuminance = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16) / 255
  const g = parseInt(hexColor.slice(3, 5), 16) / 255
  const b = parseInt(hexColor.slice(5, 7), 16) / 255
  return (0.299 * r + 0.587 * g + 0.114 * b)
}

const bgColor = (str) => {
  const backgroundColor = stringToColour(str)
  const textColor = getLuminance(backgroundColor) > 0.5 ? 'black' : 'white'
  return {
    backgroundColor,
    color: textColor,
  }
}

const clearFilters = () => {
  selectedFilters.value = []
  hideDelivered.value = false
  hideCollected.value = false
  showDeliveryOnly.value = false
  dateFilterType.value = 'collection'
  selectedDate.value = ''
  selectedLocations.value = []
}

// Lifecycle
onMounted(async () => {
  try {
    let res = null
    if (route.params.filter) {
      res = await api.get(`/api/label/${route.params.sku}/${route.params.filter}`)
    } else {
      res = await api.get('/api/label/' + route.params.sku)
    }

    // Initialize pages
    pages.value = []
    for (let i = 0; i < res.data.length; i += 12) {
      pages.value.push(res.data.slice(i, i + 12))
    }

    // Extract unique values
    const options = new Set()
    const locations = new Set()
    const dates = new Set()

    res.data.forEach(label => {
      // Extract extra info options
      if (label.extraInfo && label.extraInfo !== ' | ' && label.extraInfo.trim()) {
        options.add(label.extraInfo.trim())
      }

      // Extract collection locations
      if (label.collectionLocation) {
        locations.add(label.collectionLocation)
      }

      // Extract dates
      if (label.collectionDate) {
        dates.add(label.collectionDate)
      }
      if (label.deliveryDate) {
        dates.add(label.deliveryDate)
      }
    })

    extraInfoOptions.value = Array.from(options).sort()
    collectionLocations.value = Array.from(locations).sort()
    availableDates.value = Array.from(dates).sort()
  } catch (error) {
    console.error('Error fetching label data:', error)
  }
})
</script>

<template>
  <div>
    <!-- Filter sections -->
    <div class="filter-section print:hidden space-y-4">
      <!-- Main filter header -->
      <div class="bg-white p-4 border rounded-lg shadow-sm">
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-semibold">Filter Labels</h3>
          <div class="flex gap-4 items-center">
            <span class="text-sm text-gray-600">
              Showing {{ totalFilteredLabels }} labels
            </span>
            <button
                @click="clearFilters"
                class="px-3 py-1 text-sm border rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="!hasAnyFilters"
            >
              Clear All Filters
            </button>
          </div>
        </div>

        <!-- Status Filters -->
        <div class="mb-4 p-3 bg-gray-50 rounded">
          <h4 class="text-sm font-medium mb-2 text-gray-700">Status Filters</h4>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="flex items-center gap-2">
              <input
                  type="checkbox"
                  id="hide-delivered"
                  v-model="hideDelivered"
                  class="form-checkbox h-4 w-4 text-blue-600"
              />
              <label for="hide-delivered" class="text-sm">Hide Delivered</label>
            </div>
            <div class="flex items-center gap-2">
              <input
                  type="checkbox"
                  id="hide-collected"
                  v-model="hideCollected"
                  class="form-checkbox h-4 w-4 text-blue-600"
              />
              <label for="hide-collected" class="text-sm">Hide Collected</label>
            </div>
            <div class="flex items-center gap-2">
              <input
                  type="checkbox"
                  id="show-delivery"
                  v-model="showDeliveryOnly"
                  class="form-checkbox h-4 w-4 text-blue-600"
              />
              <label for="show-delivery" class="text-sm">Show Delivery Only</label>
            </div>
          </div>
        </div>

        <!-- Date Filters -->
        <div class="mb-4 p-3 bg-gray-50 rounded">
          <h4 class="text-sm font-medium mb-2 text-gray-700">Date Filter</h4>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label for="date-type" class="text-sm block mb-1">Filter By</label>
              <select
                  id="date-type"
                  v-model="dateFilterType"
                  class="w-full border rounded p-1.5 text-sm bg-white"
              >
                <option value="collection">Collection Date</option>
                <option value="delivery">Delivery Date</option>
              </select>
            </div>
            <div>
              <label for="date-select" class="text-sm block mb-1">Select Date</label>
              <select
                  id="date-select"
                  v-model="selectedDate"
                  class="w-full border rounded p-1.5 text-sm bg-white"
              >
                <option value="">All Dates</option>
                <option v-for="date in availableDates" :key="date" :value="date">
                  {{ formatOrdinal(date) }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Collection Location Filters -->
        <div class="mb-4 p-3 bg-gray-50 rounded">
          <h4 class="text-sm font-medium mb-2 text-gray-700">Collection Location</h4>
          <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
            <div v-for="location in collectionLocations"
                 :key="location"
                 class="flex items-center gap-2">
              <input
                  type="checkbox"
                  :id="'location-' + location"
                  v-model="selectedLocations"
                  :value="location"
                  class="form-checkbox h-4 w-4 text-blue-600"
              />
              <label :for="'location-' + location" class="text-sm">
                {{ location }}
              </label>
            </div>
          </div>
        </div>

        <!-- Extra Info Filters -->
        <div class="p-3 bg-gray-50 rounded">
          <h4 class="text-sm font-medium mb-2 text-gray-700">Extra Info</h4>
          <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
            <div v-for="option in extraInfoOptions"
                 :key="option"
                 class="flex items-center gap-2">
              <input
                  type="checkbox"
                  :id="'filter-' + option"
                  v-model="selectedFilters"
                  :value="option"
                  class="form-checkbox h-4 w-4 text-blue-600"
              />
              <label :for="'filter-' + option" class="text-sm">
                {{ option }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Labels Grid -->
    <div v-for="(page, pageIndex) in filteredPages"
         :key="pageIndex"
         class="a4-sheet">
      <div v-for="(label, labelIndex) in page"
           :key="labelIndex"
           class="label">
        <div class="row gap-2">
          <div class="col">
            <span class="h1">{{ label.id }}</span><br/>
            <span style="font-size: 8pt">{{ label.firstName }} {{ label.lastName }}</span>
          </div>
          <div class="col">
            <img :src="'https://qrcode-service.larkhall-butchers.workers.dev/'+label.id"
                 :alt="'QR Code for ' + label.id"/>
          </div>
          <div class="col">
            <span class="h1">
              {{ formatOrdinal(label.collectionDate) }} {{ formatOrdinal(label.deliveryDate) }}
            </span><br/>
            <small
                v-if="label.collectionOption === 'Collection'"
                :style="bgColor(label.collectionLocation.split(' ')[0])"
                class="p-1"
            >
              {{ label.collectionLocation.split(" ")[0] }}
            </small>
            <small
                v-if="label.collectionOption === 'Delivery'"
                class="bg-danger p-1 text-danger-fg"
            >
              Delivery
            </small>
          </div>
        </div>

        <div class="row align-items-center align-content-center align-center justify-content-center text-center">
          <div class="h3">{{ label.item }}</div>
          <div class="h2">{{ label.size }} <span v-if="label.unit === 'kg'">KG</span></div>
        </div>

        <div class="row border-top mt-2">
          <div
              :class="{
              extraInfo: ((label.sku === 'turkey-crown-bacon' &&
                        label.extraInfo !== 'bacon-lattice | stuffing-sausage-meat ') ||
                        (label.extraInfo !== '' && label.sku !== 'turkey-crown-bacon'))
            }"
              class="text-center mt-2"
              v-if="label.extraInfo !== ' | '"
          >
            {{ label.extraInfo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
* {
  background: white;
}

.extraInfo {
  background: #C3984A;
}

/* Define the A4 size and setup margins */
.a4-sheet {
  width: 210mm;
  height: 297mm;
  padding: 4.5mm 7mm;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Define the grid and label styling */
.label {
  width: 63.5mm;
  height: 72mm;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 2mm;
  margin-bottom: 0mm;
  margin-top: 0mm;
  border-radius: 1.5mm;
  vertical-align: top;
  border: 1pt solid black;
  padding: 2mm;
  overflow: hidden;
}

/* Remove right margin for every third label */
.label:nth-child(3n) {
  margin-right: 0mm;
}

@media print {
  .filter-section {
    display: none;
  }

  /* This will remove the background color when printed */
  .label {
    background: none;
  }

  .extraInfo {
    background: #C3984A;
  }

  /* This ensures the entire sheet is scaled to fit on one A4 page */
  body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .a4-sheet {
    box-sizing: border-box;
    height: 297mm;
    width: 210mm;
  }

  @page {
    margin: 0;
  }
}
</style>